/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-container {
  width: 80%;
  height: 100%;
  background-color: #eee;
  overflow-y: scroll;
  padding: 16px;
  border-radius: 8px;
}

.input-container {
  margin-top: 16px;
  display: flex;
}

.input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.bot {

  background-color: #35e444;
}

.submit-button {
  padding: 8px 16px;
  background-color: #00ff80;
  border: none;
  border-radius: 4px

}